import Vue from "vue";
import { Observable, timer } from "rxjs";

import { encode } from "@msgpack/msgpack/dist";
import * as UploadWS from "@/js/api.series.upload";
const pako = require("pako");

// Public APIs
export const convertSeriesInstanceUUIDToDatabaseId = seriesUUID => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .get(`api/series/uuid-to-id/${seriesUUID}/`)
      .then(({ id }) => resolve(id))
      .catch(error => reject(error));
  });
};

export const launchSeriesDeletionJob = ids => {
  let data = {
    ids: ids
  };
  return Vue.$http.post(`api/tasks/series-delete/`, null, data);
};

export const launchPatientDeletionJob = id => {
  let data = {
    id: id
  };
  return Vue.$http.post(`api/tasks/patient-delete/`, null, data);
};

export const launchStudyDeletionJob = id => {
  let data = {
    id: id
  };
  return Vue.$http.post(`api/tasks/study-delete/`, null, data);
};

export const sharePatientOrStudy = (shareLevel, sharedObjectId) => {
  let data = {
    shareLevel,
    sharedObjectId
  };
  return Vue.$http.post(`api/create-shared-link/`, null, data);
};

export const generateUploadLink = () => {
  return Vue.$http.post(`api/create-upload-link/`, null, null);
};

export const sendLinkViaMail = (email, link) => {
  const data = {
    email: email,
    link: link
  };
  return new Promise((resolve, reject) => {
    Vue.$http
      .post(`api/send-link/`, null, data)
      .then(res => resolve(res))
      .catch(reject);
  });
};

export const getPatients = (orderBy, page, pageSize) => {
  return new Promise((resolve, reject) => {
    const req = "api/patients/";
    let params;
    if (orderBy && page && pageSize) {
      params = {
        ordering: orderBy,
        page: page,
        page_size: pageSize
      };
    }
    Vue.$http
      .get(req, params)
      .then(({ results }) => resolve(results))
      .catch(error => reject(error));
  });
};

export const getPredictors = () => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .get("api/predictors")
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getProjects = () => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .get("api/projects")
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const getSeries = id => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .get(`api/series/${id}`)
      .then(seriesResponse => resolve({ ...seriesResponse }))
      .catch(reject);
  });
};

export const getAllStudies = () => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .get("api/studies")
      .then(({ results }) => resolve(results))
      .catch(error => reject(error));
  });
};

export const postStudy = (studyData, uploadId) => {
  return new Promise((resolve, reject) => {
    const payload = {
      studyData,
      uploadId
    };

    Vue.$http
      .post("api/studies/series", null, payload)
      .then(resp => resolve(resp.body.data))
      .catch(error => reject(error));
  });
};

export const postJob = data => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .post("api/create_job", null, data)
      .then(resp => {
        resolve(resp.body);
      })
      .catch(error => reject(error));
  });
};

// !!! TODO test this
export const sendDicomFiles = (dbSeriesId, stack) => {
  let sendCounter = 0;

  return new Observable(async sub => {
    const onUpdate = async () => {
      // Check series upload completed
      sendCounter++;
      if (
        stack.isMultiframe
          ? sendCounter == 1
          : stack.imageIds.length == sendCounter
      ) {
        sub.complete();
      }
    };

    UploadWS.setOnError(error => {
      sub.next({ error });
      onUpdate();
    });

    UploadWS.setOnMessage(data => {
      sub.next({ dbInstanceId: data.id });
      onUpdate();
    });

    // Send upload message
    let wsMessage = {
      data: {
        serie: dbSeriesId
      }
    };

    try {
      if (stack.isMultiframe) {
        wsMessage.data.image_id = stack.larvitarSeriesInstanceUID;
        console.time("deflate");
        wsMessage.data.blob = pako.deflate(stack.dataSet.byteArray);
        console.timeEnd("deflate");
        UploadWS.send(encode(wsMessage));

        wsMessage.data.blob = null;
        wsMessage = null;
      } else {
        // Send a ws message for each instance (response will be received by the previous listener)
        const queue = [...stack.imageIds];

        const next = async () => {
          if (!queue.length) {
            wsMessage = null;
            return;
          }

          const key = queue.shift();
          let buffer = await stack.instances[key].file.arrayBuffer();
          if (stack.isPDF) {
            wsMessage.data.image_id = "undefined";
          } else {
            wsMessage.data.image_id = stack.instances[key].metadata.instanceUID;
          }
          wsMessage.data.blob = pako.deflate(new Uint8Array(buffer));

          await UploadWS.send(encode(wsMessage));

          // deallocate object
          buffer = null;
          wsMessage.data.blob = null;

          next();
        };

        next();
      }
    } catch (error) {
      sub.error(error);
      onUpdate();
    }
  });
};

export const pollAsyncTasksStatus = (onData, onError) => {
  // fetch every 60 seconds
  return timer(0, 60 * 1000).subscribe(() => {
    Vue.$http
      .get("api/tasks/running")
      .then(response => onData(response))
      .catch(error => onError(error));
  });
};

export const getUserSettings = () => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .get("api/settings")
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const putUserSetting = (name, value) => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .put("api/settings", null, { [name]: value })
      .then(response => resolve(response.body))
      .catch(error => reject(error));
  });
};

export const getSharedStudy = shareId => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .get(`api/shared/${shareId}`)
      .then(response => {
        resolve(response);
      })
      .catch(error => reject(error));
  });
};

export const postMasksCreate = data => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .post(`api/masks/`, null, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => reject(error));
  });
};

export const postDeleteMasks = data => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .post(`api/delete-masks/`, null, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => reject(error));
  });
};

/**
 * Get studies with specific order and paging
 *
 */
export const getStudies = (orderBy, page, pageSize) => {
  if (!orderBy && !page) {
    return getAllStudies();
  }
  const req = "api/studies/";
  const params = {
    ordering: orderBy,
    page: page,
    page_size: pageSize
  };
  return new Promise((resolve, reject) => {
    Vue.$http
      .get(req, params)
      .then(({ results }) => resolve(results))
      .catch(error => reject(error));
  });
};

/**
 * Query studies
 */
export const queryStudies = (query, orderBy, page, pageSize) => {
  const {
    patientName,
    modality,
    studyDateGT,
    studyDateLT,
    accessionNumber
  } = query;
  const req = "api/studies/";
  const params = {
    ordering: orderBy,
    page: page,
    modality: modality,
    patient: patientName,
    study_date__gte: studyDateGT,
    study_date__lte: studyDateLT,
    accession_number: accessionNumber,
    page_size: pageSize
  };
  return new Promise((resolve, reject) => {
    Vue.$http
      .get(req, params)
      .then(({ results }) => resolve(results))
      .catch(error => reject(error));
  });
};

export const queryPatients = (query, orderBy, page, pageSize) => {
  if (!query && !orderBy && !page) {
    return getAllStudies();
  }
  const { patientName, birthDate, gender } = query;
  const req = "api/patients/";
  const params = {
    ordering: orderBy,
    page: page,
    gender: gender,
    patient: patientName,
    birth_date__gte: birthDate,
    page_size: pageSize
  };
  return new Promise(resolve => {
    Vue.$http.get(req, params).then(({ results }) => resolve(results));
  });
};

export const getSeriesFiles = seriesIDs => {
  let data = {
    ids: seriesIDs
  };
  return new Promise((resolve, reject) => {
    Vue.http
      .post(`api/download-series-files/`, data, { responseType: "blob" })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const updatePatientCustomTag = patient => {
  return new Promise((resolve, reject) => {
    Vue.http
      .patch(`api/patient/${patient.id}`, {
        custom_tag: patient.custom_tag,
        user: patient.user
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const updateStudyCustomTag = study => {
  return new Promise((resolve, reject) => {
    Vue.http
      .patch(`api/study/${study.id}`, { custom_tag: study.custom_tag })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const createProject = (projectName, workgroup) => {
  return new Promise((resolve, reject) => {
    Vue.http
      .post("api/projects", { name: projectName, workgroups: [workgroup] })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};

export const createLabel = (labelName, labelColor, projectId) => {
  return new Promise((resolve, reject) => {
    Vue.http
      .post("api/labels", {
        name: labelName,
        color: labelColor,
        project: projectId,
        predictor: null
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
};
